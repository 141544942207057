<template>
    <div>
        <div class="row justify-center q-pt-sm">

            <QQButton class="q-mb-sm"
                label="Aggiorna elenco"
                color="blue-grey"
                icon="mdi-database-refresh-outline"
                size="sm"
                @click.native="aggiornaListaQuotazioni"
            />
        </div>
        <hr>
        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />

        </div>

        <div class="row">

            <div class="col-12 col-md-2">
                <q-list bordered padding>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="selezione_rapida"
                                @click.native="onSelezionaCheck()"
                            />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Seleziona/Deseleziona tutto</q-item-label>
                            <q-item-label caption>

                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-separator spaced />

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.attesa_lavorazione"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Nuove quotazioni</q-item-label>
                            <q-item-label caption>
                                Quotazioni appena richieste ma ancora non prese in carico
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.in_lavorazione"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>In lavorazione</q-item-label>
                            <q-item-label caption>
                                Quotazioni prese in carico e in fase di definizione
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.definite"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Definite</q-item-label>
                            <q-item-label caption>
                                Quotazioni definite e trasformate in preventivi
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.rifiutate"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Rifiutate</q-item-label>
                            <q-item-label caption>
                                Quotazioni Rifiutate in fase di valutazione o dalle Compagnie
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.annullate"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Annullate</q-item-label>
                            <q-item-label caption>
                                Quotazioni Annullate
                            </q-item-label>
                        </q-item-section>
                    </q-item>


                </q-list>
            </div>

            <div class="col-12 col-md-10">
                <div class="q-px-md">
                    <q-table
                        title="Clicca su una Quotazione per attivare le opzioni di gestione"
                        :data="righe"
                        wrap-cells
                        :columns="colonne"
                        :filter="filter"
                        separator="cell"
                        row-key="id"
                        @row-click="selezionaRecordQuotazioni"
                        :rows-per-page-options="[20,40,60]"
                        class="my-sticky-header-table no-box-shadow q-mt-none">

                        <template v-slot:top-right>
                            <div class="panel-cerca">
                                <q-input dense debounce="300" outlined  v-model="filter" placeholder="Cerca" style="min-width: 350px;">
                                    <template v-slot:append>
                                        <q-icon name="search" />
                                    </template>
                                </q-input>
                            </div>
                        </template>
                        <template v-slot:header="props">
                            <q-tr :props="props">
                                <q-th
                                    v-for="col in props.cols"
                                    :key="col.name"
                                    :props="props"
                                    style="font-size: 14px; border-bottom: 1px SOLID #404040; background-color: #ffcc99; color: #000;">
                                    <strong>{{ col.label }}</strong>
                                </q-th>
                            </q-tr>
                        </template>

                        <template v-slot:body="props">
                            <q-tr :props="props"
                                style="cursor: pointer;"
                                @click.native="selezionaRecordQuotazioni(null,props.row)">
                                <q-td auto-width>
                                    {{ props.cols[0].value }}
                                </q-td>
                                <q-td auto-width v-html="props.cols[1].value">
                                </q-td>
                                <q-td auto-width>
                                    {{ props.cols[2].value }}
                                </q-td>
                                <q-td auto-width>
                                    {{props.cols[3].value}}
                                </q-td>
                                <q-td auto-width v-html="props.cols[4].value">
                                </q-td>
                                <q-td auto-width v-html="props.cols[5].value">
                                </q-td>
                                <q-td auto-width v-html="getStatoQuotazione(props)">
                                </q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </div>
            </div>

        </div>



        <br><br><br><br><br>
    </div>

</template>

<script>

import QQButton from "@/components/QQButton.vue";
import policy from "@/libs/policy"
import { mapState,mapActions } from "vuex";
import { mapFields } from 'vuex-map-fields'

export default {
    name: "TabellaQuotazioni",
    components: {
        QQButton
    },
    data() {
        return {
            filter: '',
            persistent: false,
            TipoRecordSelezionato: "",
            filtro_ricerca: {
                attesa_lavorazione: true,
                in_lavorazione: true,
                definite: false,
                rifiutate: false,
                annullate: false
            },
            selezione_rapida: null,
            righe: this.rows
        }
    },
    methods: {
        ...mapActions({
            fetchElencoQuotazioni: "gestioneQuotazioni/fetchElencoQuotazioni",
            fetchDatiPreventivo: "formPreventivo/fetchDatiPreventivo"
        }),
        getStatoQuotazione(props) {
            return props.cols[6].value.replaceAll("_"," ");
        },
        isPreventivoModificabile() {
            if (policy.PuoModificarePreventiviPropri()) {
                if (parseInt(policy.IdUnitaOperativa()) !== parseInt(this.RecordSelezionato["id_unita_operativa"])) {
                    return false
                }
            }
            if (policy.PuoModificarePreventiviGruppo()) {
                var UnitaOperativeSottostanti = policy.UnitaOperativeSottostanti();
                if (!UnitaOperativeSottostanti.includes(this.RecordSelezionato["id_unita_operativa"])) {
                    return false;
                }
            }
            var esito = policy.PuoModificarePreventiviDiTutti() ||
                   policy.PuoModificarePreventiviPropri() ||
                   policy.PuoModificarePreventiviGruppo();

            return esito;
        },
        async selezionaRecordQuotazioni(event,row) {
        /*    if (!policy.isSede()) {
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Non hai i privilegi necessari per modificare questi dati'
                });
                return;
            } */
            //console.log("row:",row);
            this.idquotazione = row.idquotazione;
            await this.fetchDatiPreventivo(row.guid_preventivo);
            console.log("event:",event);
            this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
        },
        async aggiornaListaQuotazioni() {
            await this.fetchElencoQuotazioni();
            this.onAggiornaFiltroTabella();
        },
        onSelezionaCheck() {
            this.filtro_ricerca.attesa_lavorazione = this.selezione_rapida;
            this.filtro_ricerca.in_lavorazione = this.selezione_rapida;
            this.filtro_ricerca.definite = this.selezione_rapida;
            this.filtro_ricerca.rifiutate = this.selezione_rapida;
            this.filtro_ricerca.annullate = this.selezione_rapida;

            this.inserisciFiltroTabella();
        },
        inserisciFiltroTabella() {
             this.righe = this.rows.filter(campo => {
                let stato = campo.stato;
                //console.log("stato:",stato);
                switch(stato.toUpperCase()) {
                    case "NUOVA":
                        return this.filtro_ricerca.attesa_lavorazione;
                    case "IN_LAVORAZIONE":
                        return this.filtro_ricerca.in_lavorazione;
                    case "DEFINITA":
                        return this.filtro_ricerca.definite;
                    case "RIFIUTATA":
                        return this.filtro_ricerca.rifiutate;
                    case "ANNULLATA":
                        return this.filtro_ricerca.annullate;
                }
            });
        },
         onAggiornaFiltroTabella() {
            this.selezione_rapida = null;
            this.inserisciFiltroTabella();
        },
    },
    computed: {
        ...mapState({
            Area: state => state.area,
            area: state => state.area,
            titolo_area: state => state.titolo_area,
            colonne: state => state.gestioneQuotazioni.colonne,
            rows: state => state.gestioneQuotazioni.righe,
            is_loading: state => state.gestioneQuotazioni.is_loading,
            have_errors: state => state.gestioneQuotazioni.have_errors
        }),
        ...mapFields('gestioneQuotazioni', [
            "idquotazione"
        ]),
    },
    async mounted() {
        await this.fetchElencoQuotazioni();
        this.onAggiornaFiltroTabella();
    },
    async activated() {
        await this.fetchElencoQuotazioni();
        this.onAggiornaFiltroTabella();
    }
}

</script>
