import { render, staticRenderFns } from "./TabellaQuotazioni.vue?vue&type=template&id=71f01468&"
import script from "./TabellaQuotazioni.vue?vue&type=script&lang=js&"
export * from "./TabellaQuotazioni.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpinnerHourglass,QList,QItem,QItemSection,QCheckbox,QItemLabel,QSeparator,QTable,QInput,QIcon,QTr,QTh,QTd});qInstall(component, 'directives', {Ripple});
